import React from "react"
import MissingHorse from "../MissingHorse/MissingHorse"

const Horses = ({ horses }) => {
  return (
    <div className="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s uk-text-center uk-grid">
      {horses.map((horse, i) => {
        return (
          <MissingHorse horse={horse} />
        )
      })}
    </div>
  )
}

export default Horses