import React from "react"
import Img from "gatsby-image"

const MissingHorse = ({ horse }) => {
  return (
    <div>
      <div className="uk-card uk-card-default uk-card-body">
          <Img
            fixed={horse.node.image.localFile.childImageSharp.fixed}
            imgStyle={{ position: "static" }}
            className="image-missing-horses"
          />
          <p>{horse.node.name}</p>
      </div>
    </div>
  )
}

export default MissingHorse